import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="toggle"
export class ToggleController extends Controller {
  static targets = ['target'];

  static values = {
    class: { type: String, default: 'hidden' },
  };

  open(event) {
    if (event) {
      event.preventDefault();
    }
    this.dispatch('open', {});
    this.targetTarget.classList.remove(this.classValue);
  }

  toggle(event) {
    if (event) {
      event.preventDefault();
    }

    if (this.targetTarget.classList.contains(this.classValue)) {
      this.dispatch('open', {});
      this.targetTarget.classList.remove(this.classValue);
    } else {
      this.dispatch('close', {});
      this.targetTarget.classList.add(this.classValue);
    }
  }

  close(event) {
    if (event?.type === 'click') {
      if (this.element.contains(event.target)
        || this.targetTarget.classList.contains(this.classValue)) {
        return;
      }
    }

    this.dispatch('close', {});
    this.targetTarget.classList.add(this.classValue);
  }
}
