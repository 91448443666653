import { Controller } from '@hotwired/stimulus';

export class OfferScheduleController extends Controller {
  static targets = ['day', 'delete', 'breakInput', 'shiftStart', 'shiftEnd'];

  static values = {
    selectedClass: { type: String, default: 'x-button--primary' },
    hiddenClass: { type: String, default: 'hidden' },
    errorClass: { type: String, default: 'x-input--error' },
  };

  #selectedDays = [];
  // break is a keyword so I'm calling this siesta :D
  #siesta = null;

  get selectedDays() {
    return this.#selectedDays;
  }

  get deletingEnabled() {
    return this.deleteTarget.classList.contains(this.hiddenClassValue);
  }

  set deletingEnabled(enabled) {
    if (enabled) {
      this.deleteTarget.classList.remove(this.hiddenClassValue);
    } else {
      this.deleteTarget.classList.add(this.hiddenClassValue);
    }
  }

  connect() {
    for (const dayTarget of this.dayTargets) {
      if (dayTarget.classList.contains(this.selectedClassValue)) {
        this.#selectedDays.push(this.dayTargets.indexOf(dayTarget));
      }
    }
  }

  delete(event) {
    event.preventDefault();
    this.element.remove();
  }

  enableAllDays() {
    for (const dayElement of this.dayTargets) {
      dayElement.disabled = false;
    }
  }

  selectDays(days) {
    this.#selectedDays = days;

    days.map((day) => this.dayTargets[day])
      .forEach((dayElement) => dayElement.classList.add(this.selectedClassValue));
  }

  disableDays(days) {
    for (const day of days) {
      const dayElement = this.dayTargets[day];
      dayElement.classList.remove(this.selectedClassValue);
      dayElement.disabled = true;

      const index = this.#selectedDays.indexOf(day);
      if (index !== -1) {
        this.#selectedDays.splice(index, 1);
      }
    }
  }

  toggleDay(event) {
    event.preventDefault();

    const day = this.dayTargets.indexOf(event.srcElement);
    const index = this.#selectedDays.indexOf(day);
    if (index === -1) {
      this.#selectedDays.push(day);
      event.srcElement.classList.add(this.selectedClassValue);
    } else {
      this.#selectedDays.splice(index, 1);
      event.srcElement.classList.remove(this.selectedClassValue);
    }

    this.dispatch('days-changed');
  }

  breakUpdated(event) {
    this.#siesta = event.detail;
    this.validate();
  }

  validate() {
    const result = this.#validateBreak();

    this.breakInputTarget.classList.toggle(this.errorClassValue, !result);

    return result;
  }

  #validateBreak() {
    if (this.#siesta === null) {
      return true;
    }

    const shiftStart = this.shiftStartTarget.value;
    const shiftEnd = this.shiftEndTarget.value;

    if (shiftStart === '' || shiftEnd === '') {
      return true;
    }

    let shift = this.#parseValues(shiftStart, shiftEnd);
    let siesta = this.#parseValues(this.#siesta.start, this.#siesta.end);

    return (siesta.start >= shift.start) && (siesta.end <= shift.end);
  }

  #timeValueToTimestamp(value) {
    const splitted = value.split(':');
    return parseInt(splitted[0]) * 24 + parseInt(splitted[1]);
  }

  #parseValues(startValue, endValue) {
    const start = this.#timeValueToTimestamp(startValue);
    let end = this.#timeValueToTimestamp(endValue);

    if (start > end) {
      end += 24 * 60;
    }

    return { start: start, end: end };
  }
}
