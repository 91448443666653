import { Controller } from '@hotwired/stimulus';
import { parseDate } from '@app/scripts/parseDate';

export class OfferFormController extends Controller {
  static targets = ['dateFrom', 'dateTo'];
  static outlets = ['offer-schedule'];

  offerScheduleOutletConnected() {
    this.weekdaysChanged();
  }

  offerScheduleOutletDisconnected() {
    this.weekdaysChanged();
  }

  offerDatesChanged(event) {
    if (event.srcElement === this.dateFromTarget) {
      this.#autofillDateToIfNeeded();
    }
    this.#handleWeekdays(true);
  }

  weekdaysChanged() {
    this.#handleWeekdays(false);
  }

  #handleWeekdays(shouldAutoselect) {
    this.#enableAllWeekdays();
    const enabledDays = this.#disableWeekdaysBasedOnOfferDates(shouldAutoselect);
    this.#disableAlreadySelectedWeekdays();

    if (shouldAutoselect) {
      this.#preselectWeekdaysIfNeeded(enabledDays);
    }

    this.#disableDeletingIfNeeded();
  }

  #enableAllWeekdays() {
    for (const scheduleController of this.offerScheduleOutlets) {
      scheduleController.enableAllDays();
    }
  }

  #disableAlreadySelectedWeekdays() {
    for (const sourceController of this.offerScheduleOutlets) {
      const selectedDays = sourceController.selectedDays;
      for (const destController of this.offerScheduleOutlets) {
        if (destController == sourceController) {
          continue;
        }

        destController.disableDays(selectedDays);
      }
    }
  }

  #disableWeekdaysBasedOnOfferDates() {
    const fromText = this.dateFromTarget.value;
    const toText = this.dateToTarget.value;

    if (fromText === '' || toText === '') {
      return [];
    }

    const fromDate = new Date(fromText);
    const toDate = new Date(toText);
    const fromTimestamp = fromDate.getTime();
    const toTimestamp = toDate.getTime();

    if (isNaN(fromTimestamp) || isNaN(toTimestamp)) {
      return;
    }

    if (fromTimestamp > toTimestamp) {
      return [];
    }

    const daysDifference = (toTimestamp - fromTimestamp) / (24 * 60 * 60 * 1000);

    const startWeekday = (fromDate.getDay() + 6) % 7; // convert to Monday = 0
    let disabledDays = [0, 1, 2, 3, 4, 5, 6];
    for (let day = startWeekday; day <= startWeekday + daysDifference; ++day) {
      disabledDays.splice(disabledDays.indexOf(day % 7), 1);
    }
    for (const controller of this.offerScheduleOutlets) {
      controller.disableDays(disabledDays);
    }

    const allEnabledDays = [0, 1, 2, 3, 4, 5, 6].filter((x) => !disabledDays.includes(x));
    return allEnabledDays;
  }

  #preselectWeekdaysIfNeeded(days) {
    if (this.offerScheduleOutlets.length !== 1) {
      return;
    }

    this.offerScheduleOutlets[0].selectDays(days);
  }

  #disableDeletingIfNeeded() {
    const deletingEnabled = (this.offerScheduleOutlets.length > 1);
    this.offerScheduleOutlets.forEach((c) => c.deletingEnabled = deletingEnabled);
  }

  #autofillDateToIfNeeded() {
    const dateFrom = parseDate(this.dateFromTarget);

    if (dateFrom === null) {
      return;
    }

    const dateTo = parseDate(this.dateToTarget);

    if (dateTo < dateFrom) {
      this.dateToTarget.value = this.dateFromTarget.value;
    }
  }
}
