import { Controller } from '@hotwired/stimulus';

export class PlacesAutocompleteController extends Controller {
  static targets = ['input', 'results', 'lat', 'lng'];
  static values = {
    types: { type: Array, default: ['address'] },
    biasLat: { type: Number, default: undefined },
    biasLng: { type: Number, default: undefined },
    lang: { type: String, default: document.documentElement.lang },
    country: { type: Array, default: ['dk', 'se'] },
  };

  #service = null;
  #geocoder = null;
  #selectedPlace = null;

  initialize() {
    this.#geocoder = new window.google.maps.Geocoder();
    window.google.maps.importLibrary('places').then(({ AutocompleteService }) => {
      this.#service = new AutocompleteService();
    });
  }

  connect() {
    this.#selectedPlace = {
      lat: this.latTarget.value ? parseInt(this.latTarget.value, 10) : undefined,
      lng: this.lngTarget.value ? parseInt(this.lngTarget.value, 10) : undefined,
      description: this.inputTarget.value,
      placeId: undefined,
    };
  }

  onChange(event) {
    const searchPhrase = event.currentTarget.value;
    const locationBias = this.biasLatValue && this.biasLngValue ? { lat: this.biasLatValue, lng: this.biasLngValue } : null;
    const componentRestrictions = this.countryValue ? { country: this.countryValue } : null;

    if (searchPhrase.trim() === '') {
      this.clearResults();
      return;
    }

    this.#service.getPlacePredictions(
      {
        input: searchPhrase,
        types: this.typesValue,
        language: this.langValue,
        locationBias,
        componentRestrictions,
      },
      (places) => this.#renderResults(places),
    );
  }

  onClick(event) {
    this.#geocoder.geocode({ placeId: event.params.id, language: this.langValue }, ([result]) => {
      this.#selectedPlace = {
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
        description: result.formatted_address,
        placeId: result.place_id,
      };
      this.#renderSelection();
      this.clearResults();
    });
  }

  onBlur() {
    this.#renderSelection();
  }

  clearResults() {
    this.#renderResults([]);
  }

  #renderResults(places) {
    const newElements = (places ?? []).map(this.#mapPlaceToElement);

    this.resultsTarget.replaceChildren(...newElements);
  }

  #renderSelection() {
    this.latTarget.value = this.#selectedPlace.lat ?? '';
    this.lngTarget.value = this.#selectedPlace.lng ?? '';
    this.inputTarget.value = this.#selectedPlace.description ?? '';
  }

  #mapPlaceToElement(place) {
    const container = document.createElement('div');
    const descriptionElement = document.createElement('div');

    container.append(descriptionElement);
    container.classList.add('x-input__dropdown-item', 'hover:bg-primary-200');
    container.dataset.action = 'click->places-autocomplete#onClick';
    container.dataset.placesAutocompleteIdParam = place.place_id;

    descriptionElement.classList.add('grow', 'text-md', 'cursor-pointer');
    descriptionElement.textContent = place.description;

    return container;
  }
}
