import { html } from 'htm/preact';
import { createRef } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';

import { Modal, useModal } from '@app/widgets/components/Modal';
import { renderComponent } from '@app/dom';
import { i18n } from '@app/i18n';

const OfferSaveAsTemplateModal = ({ parentElement }) => {
  const [isOpen, setIsOpen, triggerData] = useModal(parentElement);
  const title = i18n.t('offer.edit.save_template_modal.title');
  const onCloseButtonClick = () => setIsOpen(false);
  const [templateName, setTemplateName] = useState('');
  const inputRef = createRef();

  useEffect(() => {
    if (!isOpen) {
      setTemplateName('');
    }
  }, [isOpen]);

  useEffect(() => {
    inputRef.current = document.getElementById(triggerData.associatedFormField);
  });

  const onSubmit = useCallback(() => {
    const form = inputRef.current.form;
    const trigger = form.elements['save_as'];
    const dateFromInput = form.elements['offer[date_from]'];
    const isDateFromRequired = dateFromInput.required;
    const lastSaveAsValue = trigger.value;

    dateFromInput.required = false;
    inputRef.current.value = templateName;
    trigger.value = 'template';

    try {
      form.requestSubmit(trigger);
    } catch (error) {
      dateFromInput.required = isDateFromRequired;
      trigger.value = lastSaveAsValue;
      alert(error);
    }
  });

  const onChange = useCallback((event) => {
    setTemplateName(event.target.value);
  });

  if (!isOpen) {
    return null;
  }

  return html`<${Modal} title=${title} isOpen=${isOpen} onRequestClose=${onCloseButtonClick}>
    <div className="u-padding--l">
      <label class="c-label">
        <input type="text" onChange=${onChange} placeholder="" class="c-input u-size--match-parent-width" />
        <span class="c-label__text">${i18n.t('offer.edit.save_template_modal.template_name')}</span>
      </label>
      <div className="o-layout o-layout--justify-end u-margin-top--xl">
        <div className="o-layout__item u-padding-horizontal--xs">
          <span className="c-button c-button--secondary" onClick="${onCloseButtonClick}">
            ${i18n.t('common.cancel')}
          </span>
        </div>
        <div className="o-layout__item u-padding-horizontal--xs">
          <button onClick=${onSubmit} class="c-button c-button--primary">${i18n.t('common.save')}</button>
        </div>
      </div>
    </div>
  </${Modal}>`;
};

renderComponent('#offer-save-as-template-modal', OfferSaveAsTemplateModal);
